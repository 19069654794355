const names = [
  {
    name: 'Bill',
    tags: ['salvation'],
  },
  {
    name: 'Sarah',
    tags: ['salvation'],
  },
  {
    name: 'Charlie',
    tags: ['salvation'],
  },
  {
    name: 'Cheryl',
    tags: ['salvation'],
  },
  {
    name: 'Tom',
    tags: ['salvation'],
  },       
  {
    name: 'Lisa',
    tags: ['salvation'],
  },
  {
    name: 'Coworker John',
    tags: ['salvation'],
  },        
  {
    name: 'Neighbors Steve & Emily',
    tags: ['salvation'],
  },        
]

export default names