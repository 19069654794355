const verses = [
  {
    book: 'John',
    chapter: 6,
    verse: 44,
    theme: 'That God would draw this person to Himself',
    tags: ['draw near', 'salvation'],
    text: "No one can come to me unless the Father who sent me draws them, and I will raise them up at the last day.",
  },
  {
    book: 'Jeremiah',
    chapter: 31,
    verse: 3,
    theme: 'That God would draw this person to Himself with His love and mercy',
    tags: ['draw near', 'salvation'],
    text: 'The LORD appeared to us in the past, saying: "I have loved you with an everlasting love; I have drawn you with unfailing kindness."',
  },
  {
    book: 'Acts',
    chapter: 2,
    verse: 39,
    theme: 'That God would call this person to Himself',
    tags: ['salvation', 'draw near'],
    text: 'The promise is for you and your children and for all who are far off—for all whom the Lord our God will call.',
  },  
  {
    book: 'Acts',
    chapter: 17,
    verse: 27,
    theme: 'That this person would seek to know God and would find Him',
    tags: ['salvation'],
    text: 'God did this so that they would seek him and perhaps reach out for him and find him, though he is not far from any one of us.',
  },  
  {
    book: '1 Thessalonians',
    chapter: 2,
    verse: 13,
    theme: 'That this person would believe the scriptures',
    tags: ['faith'],
    text: 'And we also thank God continually because, when you received the word of God, which you heard from us, you accepted it not as a human word, but as it actually is, the word of God, which is indeed at work in you who believe.',
  },
  {
    book: 'Romans',
    chapter: 10,
    verse: 17,
    theme: "That this person would have faith from hearing God's word",
    tags: ['faith', 'salvation'],
    text: 'Consequently, faith comes from hearing the message, and the message is heard through the word about Christ.',
  }, 
]

export default verses