import * as React from 'react'
import { BIBLE_API_KEY } from './config'
import verses from './verses'
import names from './names'

export default function App() {
  // This API.Bible thing may be broken!
  const fetchVerse = async () => {
    await fetch('https://api.scripture.api.bible/v1/bibles', {
      headers: {
        'Content-Type': 'application/json',
        'api-key': BIBLE_API_KEY,
      },
    })
  }

  React.useEffect(() => {
    // fetchVerse()
  }, [])

  const getRandomIndex = (max) => {
    return Math.floor(Math.random() * max)
  }

  const [verse, setVerse] = React.useState(
    verses[getRandomIndex(verses.length)]
  )
  const [name, setName] = React.useState(names[getRandomIndex(names.length)])

  const randomize = () => {
    let newVal = names[getRandomIndex(names.length)]
    while (newVal === name) {
      newVal = names[getRandomIndex(names.length)]
    }
    setName(newVal)

    newVal = verses[getRandomIndex(verses.length)]
    while (newVal === verse) {
      newVal = verses[getRandomIndex(verses.length)]
    }
    setVerse(newVal)
  }

  return (
    <div className="container mx-auto max-w-md md:max-w-lg overflow-x-hidden">
      <div className="flex flex-col min-h-screen w-full pb-28">
        <div className="flex-1 max-h-[10vh] sm:max-h-[20vh]" aria-hidden />
        <div
          className="flex-0 basis-8 text-lg text-gray-300 font-bold px-6"
          aria-hidden
        >
          Pray for...
        </div>

        <section className="flex-grow my-5 space-y-6 md:space-y-10">
          <div className="px-6 text-4xl md:text-6xl md:font-light  font-thin leading-none space-y-4">
            <h1 className="text-gray-600 md:text-gray-500">
              <span className="sr-only">Pray for </span>
              {name.name}
            </h1>
            <div className="text-lg md:text-2xl font-light md:font-extralight text-gray-500 md:text-gray-600">
              {verse.theme}
            </div>
          </div>

          <div className="p-6 text-gray-500 text-md bg-gray-100 md:text-xl">
            <span className="font-bold text-gray-700">
              {verse.book} {verse.chapter}:{verse.verse}
            </span>
            {' · '}
            {verse.text}
          </div>
        </section>
      </div>

      <div className="text-center mt-16 fixed bottom-0 left-0 w-full py-8 bg-white sm:py-20">
        <div className="relative">
          <div
            className="absolute bottom-[88px] left-0 bg-gradient-to-t to-transparent from-white h-10 z-10 w-full"
            aria-hidden
          />

          <button
            onClick={randomize}
            class="inline-block text-purple-500 ring-4 ring-purple-500 hover:text-white hover:ring-purple-500 hover:bg-purple-500 active:ring-purple-600 active:bg-purple-600 w-full max-w-[300px] py-3 text-2xl font-bold rounded-full transition-colors duration-300"
          >
            <span>Next!</span>
          </button>
        </div>
      </div>
    </div>
  )
}
